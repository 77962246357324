<template>
  <div class="wrap">
    <div class="box">
      <img :src="imageUrl" :alt="imageAltText" />
      <div :style="headlineColor" class="boxContent">
        <h2>{{ headlineText }}</h2>
        <p>
          <slot />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    imageAltText: {
      type: String,
      required: true,
    },
    headlineColor: {
      type: String,
      required: true,
    },
    headlineText: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  width: 70%;
  justify-content: center;
  margin: 0 auto;
}
.box {
  display: flex;
  height: 15rem;
  flex-direction: row;
  gap: 5rem;
  align-items: center;
  img {
    width: 20%;
    place-self: center;
  }
  .boxContent {
    display: flex;
    width: 70%;
    flex-direction: column;
    gap: 1rem;
  }
  p {
    font-size: 14px;
    width: 80%;
    line-height: 28px;
    font-weight: 100;
  }
}
@media only screen and (min-width: 601px) and (max-width: 991px) {
  .wrap {
    min-height: 18rem;
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .wrap {
    width: 100%;
    text-align: center;
    p {
      margin: 0 auto;
    }
  }
  .box {
    flex-direction: column;
    gap: 2rem;
    height: auto;
    img {
      position: relative;
      bottom: -3rem;
    }
  }
}
</style>
